import React from 'react';
import { graphql } from 'gatsby';

import { HomeLayout } from '../components/homeLayout';
import SEO from '../components/seo';

// eslint-disable-next-line import/extensions,import/no-unresolved
import { VolunteerQuery } from '../../types/graphql-types';
import BannerPage from '../components/join/bannerPage';
import VolunteerComponent from '../components/join/volunteer';

type Props = {
  data: VolunteerQuery;
};

const Volunteer: React.FC<Props> = ({ data }: Props) => (
  <HomeLayout pathname="/volunteer">
    <SEO
      title="White Reparations to African People"
      description="Uhuru Solidarity Movement is the reparations organization of white people under the leadership of the African People's Socialist Party"
    />

    <BannerPage joinBanner={data.joinBanner}>
      <VolunteerComponent />
    </BannerPage>
  </HomeLayout>
);

export const query = graphql`
  query Volunteer {
    joinBanner: file(relativePath: { eq: "volunteer-banner-3.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 1720)
      }
    }
  }
`;

export default Volunteer;
