import React, { useState } from 'react';

import { Header1 } from '../headers';
import useVolunteerForm from './useVolunteerForm';
import VolunteerForm from './volunteerForm';
import { VolunteerSteps } from './types';

const Volunteer: React.FC = () => {
  const [step, setStep] = useState<VolunteerSteps>(
    VolunteerSteps.VolunteerInfo
  );

  const {
    volunteerFormData,
    error,
    loading,
    onInputChange,
    onSubmit,
  } = useVolunteerForm(() => setStep(VolunteerSteps.ThankYouPage));

  return (
    <div className="pb-10">
      <Header1 tag="h1" className="text-usm-dark-red uppercase text-center">
        Volunteer with Uhuru Solidarity
      </Header1>
      {step === VolunteerSteps.VolunteerInfo && (
        <>
          <p className="text-center mt-4 md:px-16">
            No matter where you’re located and wherever your skills and
            interests lie, there is a place for you in this movement. Sign up to
            attend a volunteer orientation and learn how you can get involved
            and make a difference, under the dynamic and powerful leadership of
            the African working class.
          </p>
          <VolunteerForm
            onSubmit={onSubmit}
            onInputChange={onInputChange}
            data={volunteerFormData}
            error={error}
            loading={loading}
          />
        </>
      )}
      {step === VolunteerSteps.ThankYouPage && (
        <p className="text-center mt-4 md:px-16">
          Thank you for your submission. Someone from USM will follow-up with
          you about how to get involved in your area. In the meantime,{' '}
          <a
            href="https://calendly.com/uhurusolidarity/orientation"
            target="_blank"
            rel="noreferrer"
          >
            make sure you sign up for orientation.
          </a>
        </p>
      )}
    </div>
  );
};

export default Volunteer;
