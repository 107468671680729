import React, { useReducer, useState } from 'react';
import { PaymentFormFieldId, VolunteerFormValues } from './types';
import { formFieldConfig } from './constants';

const initialFormState: VolunteerFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  location: '',
};

// // TODO: remove test values
// const initialFormState: VolunteerFormValues = {
//   firstName: 'D',
//   lastName: 'K',
//   email: 'jdkelleh@gmail.com',
//   phone: '1',
//   location: 'somewhere',
// };

// eslint-disable-next-line no-shadow
enum VolunteerFormActionTypes {
  inputChanged = 'INPUT_CHANGED',
}

type VolunteerFormAction = {
  type: VolunteerFormActionTypes;
  fieldId: PaymentFormFieldId;
  newValue: string;
};

const volunteerFormReducer: React.Reducer<
  VolunteerFormValues,
  VolunteerFormAction
> = (
  state: VolunteerFormValues,
  action: VolunteerFormAction
): VolunteerFormValues => {
  switch (action.type) {
    case 'INPUT_CHANGED':
      return {
        ...state,
        [action.fieldId]: action.newValue,
      };
    default:
      return state;
  }
};

type VolunteerFormState = {
  volunteerFormData: VolunteerFormValues;
  onInputChange: (fieldId: PaymentFormFieldId, newValue: string) => void;
  onSubmit: () => void;
  loading: boolean;
  error?: string;
};

const useVolunteerForm = (onPostSubmit?: () => void): VolunteerFormState => {
  const [volunteerFormData, volunteerFormDispatch] = useReducer<
    React.Reducer<VolunteerFormValues, VolunteerFormAction>
  >(volunteerFormReducer, initialFormState);

  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const onInputChange = (fieldId: PaymentFormFieldId, newValue: string) => {
    volunteerFormDispatch({
      type: VolunteerFormActionTypes.inputChanged,
      fieldId,
      newValue,
    });
  };

  const onSubmit = async () => {
    let requiredError: string | undefined;
    Object.entries(volunteerFormData).forEach(([key, value]) => {
      if (requiredError) {
        return;
      }
      const config = formFieldConfig[key as PaymentFormFieldId];
      const label = config?.label || key;
      if (config?.required) {
        // if value is falsy then return an error.
        if (!value) {
          requiredError = `${label} is a required field`;
        }
      }
    });

    if (requiredError) {
      setError(requiredError);
      return;
    }

    setLoading(true);

    const query = `mutation VolunteerQuery($firstName: String!, $lastName: String!, $email: String!, $phone: String!, $location: String!) {
        volunteer(firstName: $firstName, lastName: $lastName, email: $email, phone: $phone, location: $location) {
          email
        }
      }`;
    await fetch('/api/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: {
          ...volunteerFormData,
        },
      }),
    });

    setLoading(false);

    if (onPostSubmit) {
      onPostSubmit();
    }
  };

  return {
    volunteerFormData,
    error,
    loading,
    onInputChange,
    onSubmit,
  };
};

export default useVolunteerForm;
