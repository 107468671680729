import React from 'react';

import { volunteerFormConfig } from './constants';
import FormInput from './formInput';
import { CTAButton } from '../linkComponent';
import { PaymentFormFieldId, VolunteerFormValues } from './types';

type VolunteerFormProps = {
  onSubmit: () => void;
  onInputChange: (id: PaymentFormFieldId, value: string) => void;
  data: VolunteerFormValues;
  loading: boolean;
  error?: string;
};

const VolunteerForm: React.FC<VolunteerFormProps> = ({
  onSubmit,
  onInputChange,
  data,
  loading,
  error,
}: VolunteerFormProps) => (
  <>
    <div className="flex flex-wrap mx-auto mt-6 md:px-20 justify-between">
      {error && (
        <div className="text-center min-w-full text-xs text-usm-dark-red">
          {error}
        </div>
      )}
      {volunteerFormConfig.map((config) => (
        <FormInput
          config={config}
          value={data[config.id]}
          onChange={(newValue) =>
            onInputChange(config.id as PaymentFormFieldId, newValue)
          }
          key={config.id}
          formHasError={!!error}
        />
      ))}
    </div>
    <CTAButton
      onToggle={onSubmit}
      className="px-6 uppercase max-w-md mt-6 mx-auto relative"
    >
      {loading && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div
            style={{ borderTopColor: 'transparent' }}
            className="w-10 h-10 border-4 border-white border-solid rounded-full animate-spin"
          />
        </div>
      )}
      <div className={loading ? 'opacity-0' : ''}>Submit</div>
    </CTAButton>
  </>
);

VolunteerForm.defaultProps = {
  error: undefined,
};

export default VolunteerForm;
